import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styles from './index.css';
import HomePage from './pages/HomePage';
import ChiSiamoPage from './pages/ChiSiamo';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<><HomePage /></>} />
        <Route path="/chi-siamo" exact element={<><ChiSiamoPage /></>} />
        <Route path="/attivita" exact element={<><HomePage /></>} />
        <Route path="/notizie" exact element={<><HomePage /></>} />
        <Route path="/foto" exact element={<><HomePage /></>} />
        <Route path="/contatti" exact element={<><HomePage /></>} />
      </Routes>
    </Router>
  );
}

export default App;
