import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function HomePage() {
    return (
      <div>
        <p>Homepage</p>
      </div>
    );
  }

  export default HomePage;