import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function ChiSiamo() {
    return (
      <div>
         <p>Chi siamo</p>
      </div>
    );
  }

  export default ChiSiamo;